export function PriceFormatter(price: string | number) {
  if (Number.isNaN(parseFloat(price.toString()))) return;
  return parseFloat(price.toString()).toFixed(2).replace(".", ",") + " €";
}

export function CropProductName(name: string, length?: number) {
  const MAX_LENGHT = length ? length : 20;
  return name.length > MAX_LENGHT
    ? name.substr(0, MAX_LENGHT - 1) + "..."
    : name;
}

export function pickTextColor(bgColor: string) {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#323232" : "#fafafa";
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const CheckSlugIsTP = (slug: string) => {
  return [
    "test",
    // "toni-pizzeria-torrent",
    // "tonipizzeria",
    // "tonipizzeria-alcacer",
    // "tonipizzeria-aldaia",
    // "tonipizzeria-paiporta",
    // "tonipizzeria-quart",
    // "tonipizzeria-sagunt",
    // "tonipizzeria-torrent",
  ].includes(slug);
};
