import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { lazy, Suspense, useState } from "react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Theme variables */
import "./theme/variables.css";

/* Prime React */
import { locale, addLocale } from "primereact/api";

import "primereact/resources/themes/md-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.css";
import "/node_modules/primeflex/primeflex.css";

import { AppContextInterface, AppCtxProvider } from "./business/Context";
import { GetOrderType } from "./business";
import { ORDER_TYPES } from "./constants";
import { CircularProgress } from "@mui/material";
import BrandWatermark from "./components/loaders/BrandWatermark";

// Pages Restaurant LL
const Landing = lazy(() => import("./pages/restaurant/landing"));
const Home = lazy(() => import("./pages/restaurant/home"));
const Menu = lazy(() => import("./pages/restaurant/menu"));
const Products = lazy(() => import("./pages/restaurant/menu/products"));
const CuentaDetalle = lazy(() => import("./pages/restaurant/BillDetail"));
const PedidoDetalle = lazy(() => import("./pages/restaurant/OrderCart"));
const DeliveryZipCode = lazy(() => import("./pages/restaurant/ZipCode"));
const Booking = lazy(() => import("./pages/restaurant/booking"));
const Reservation = lazy(() => import("./pages/restaurant/Reservation"));
const OrderData = lazy(() => import("./pages/restaurant/OrderData"));
const OrderSuccess = lazy(() => import("./pages/restaurant/OrderSuccess"));
const Widget = lazy(() => import("./pages/restaurant/booking/Widget"));
const Contact = lazy(() => import("./pages/restaurant/Contact"));

const TpOrderOk = lazy(() => import("./pages/restaurant/TpOrderOk"));
const TpOrderKo = lazy(() => import("./pages/restaurant/TpOrderKo"));

const Inicio = lazy(() => import("./pages/corporate/Inicio"));

setupIonicReact();

const Loader = () => (
  <div id="loader" className="main_loader">
    <div
      style={{
        flex: 1,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="inherit" size={24} />
      <p>Encendiendo el horno...</p>
    </div>
    <BrandWatermark />
  </div>
);

const App: React.FC = () => {
  // Prime React i18n
  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });
  locale("es");

  // Context
  const [fontColor, setFontColor] = useState<string>("#121212");
  const [orderTs, setOrderTs] = useState<number>(0);
  const [local, setLocal] = useState<boolean>(false);
  const [delivery, setDelivery] = useState<boolean>(false);
  const [takeaway, setTakeaway] = useState<boolean>(false);
  const [table, setTable] = useState<string>("X");
  const [orderType, setOrderType] = useState<ORDER_TYPES | null>(
    GetOrderType()
  );

  const defaultCtx: AppContextInterface = {
    orderTs,
    fontColor,
    local,
    delivery,
    takeaway,
    table,
    orderType,
    setOrderTs,
    setFontColor,
    setLocal,
    setDelivery,
    setTakeaway,
    setTable,
    setOrderType,
  };

  return (
    <AppCtxProvider value={defaultCtx}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/landing/:restaurant_slug">
              <Suspense fallback={<Loader />}>
                <Landing />
              </Suspense>
            </Route>
            <Route exact path="/landing">
              <Suspense fallback={<Loader />}>
                <Landing />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/home" exact>
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/contact" exact>
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/zip_code" exact>
              <Suspense fallback={<Loader />}>
                <DeliveryZipCode />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/menu" exact>
              <Suspense fallback={<Loader />}>
                <Menu />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/menu/products" exact>
              <Suspense fallback={<Loader />}>
                <Products />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/order-detail" exact>
              <Suspense fallback={<Loader />}>
                <PedidoDetalle />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/order-data" exact>
              <Suspense fallback={<Loader />}>
                <OrderData />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/bill-detail" exact>
              <Suspense fallback={<Loader />}>
                <CuentaDetalle />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/pedido-realizado" exact>
              <Suspense fallback={<Loader />}>
                <OrderSuccess />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/booking">
              <Suspense fallback={<Loader />}>
                <Booking />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/reservation" exact>
              <Suspense fallback={<Loader />}>
                <Reservation />
              </Suspense>
            </Route>
            {/* Widget embebible */}
            <Route path="/:restaurant_slug/reservation-widget-embed" exact>
              <Suspense fallback={<Loader />}>
                <Widget />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/order-payment-ok" exact>
              <Suspense fallback={<Loader />}>
                <TpOrderOk />
              </Suspense>
            </Route>
            <Route path="/:restaurant_slug/order-payment-ko" exact>
              <Suspense fallback={<Loader />}>
                <TpOrderKo />
              </Suspense>
            </Route>
            <Route exact path="/">
              <Suspense fallback={<Loader />}>
                <Inicio />
              </Suspense>
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AppCtxProvider>
  );
};

export default App;
