import React from "react";
import { ORDER_TYPES } from "../constants";
import { CategoryAddon } from "../services";

export interface SimpleProductInCart {
  id: number;
  price: number;
  amount: number;
  observation: string;
  name: string;
  addons: CategoryAddon[];
  delay: number;
  can_delay: boolean;
  menu_category_id?: number;
}

export interface Cart {
  products: SimpleProductInCart[];
  type: ORDER_TYPES;
}

export interface MenuProduct {
  id: number;
  price: number;
  amount: number;
  name: string;
  menu_category_id: number;
  supplement: number;
  observation: string;
  addons?: CategoryAddon[];
  category_id: number;
}

export interface MenuInCart {
  products: MenuProduct[];
  name: string;
  menu_id: number;
  menu_price: number;
  type: ORDER_TYPES;
}
export interface AppContextInterface {
  orderTs: number;
  fontColor: string;
  local?: boolean;
  delivery?: boolean;
  takeaway?: boolean;
  total?: number;
  table?: string | undefined;
  orderId?: number | undefined;
  orderType: ORDER_TYPES | null;
  // Context hooks
  setOrderTs: (orderTs: number) => void;
  setFontColor: (color: string) => void;
  setLocal: (local: boolean) => void;
  setDelivery: (deliver: boolean) => void;
  setTakeaway: (takeaway: boolean) => void;
  setTable: (table: string) => void;
  setOrderType: (orderType: ORDER_TYPES | null) => void;
}
const ApplicationContext = React.createContext<AppContextInterface | null>(
  null
);

export const AppCtxProvider = ApplicationContext.Provider;
export const AppCtxConsumer = ApplicationContext.Consumer;

export default ApplicationContext;
