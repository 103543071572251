const BrandWatermark = () => (
  <p
    style={{
      display: "flex",
      alignItems: "flex-end",
      gap: 3,
      marginBottom: "1rem",
      fontSize: "1rem",
    }}
  >
    <span style={{ margin: "4px 0" }}>With ❤️ by</span>
    <img src="./assets/fds.png" height={26} />
  </p>
);

export default BrandWatermark;
