const API_VERSION = "v1/";
const API_DIRECTORY = "client/";

const LOCAL_API = "http://localhost:8000/api/";
const VPS_PRE_API = "https://api.vps.fooddynamic.es/api/";
const VPS_PRO_API = "https://preapi.vps.fooddynamic.es/api/";
export const API_ENDPOINT = VPS_PRE_API + API_VERSION + API_DIRECTORY;
// export const CLIENT_APP_URL = "https://app.fooddynamic.es/";
// export const CLIENT_APP_URL = "https://preapp.fooddynamic.es/";
export const CLIENT_APP_URL = "http://localhost:8100/";

/* Manejo de fechas */
export const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

/* Dias de la semana */
export enum WEEK_DAYS {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export const weekDaysName: { [key in WEEK_DAYS]: string } = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo",
};
