/* Restaurante */
export enum RESTAURANT_STATE {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DEBT = "DEBT",
}

export enum ORDER_PRODUCT_STATE {
  PENDING = "PENDING",
  SERVED = "SERVED",
  REMOVED = "REMOVED",
}

export enum ORDER_STATE {
  PENDING = "PENDING",
  IDLE = "IDLE",
  COMPLETED = "COMPLETED",
}

/* Mesa */
export enum TABLE_STATE {
  LOADING = "LOADING",
  EMPTY = "EMPTY_TABLE",
  ACTIVE = "ACTIVE_TABLE",
  PENDING = "PENDING_TABLE",
  PAYING = "PAYING_TABLE",
  PAYED = "PAYED_TABLE",
}

/* Producto en pedido */
export const PENDING_ORDER_PRODUCT_STATE: string = "PENDING_ORDER_PRODUCT";
export const SERVED_ORDER_PRODUCT_STATE: string = "SERVED_ORDER_PRODUCT";
