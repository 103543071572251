import { TABLE_STATE } from "./States";

/**
 * Colores de estado de una Mesa
 */
export const TableColors = [
  {
    STATE: TABLE_STATE.EMPTY,
    COLOR: "#fcfcfc",
    DESCRIPTION: "Vacía",
  },
  {
    STATE: TABLE_STATE.ACTIVE,
    COLOR: "#dddddd",
    DESCRIPTION: "Ocupada",
  },
  {
    STATE: TABLE_STATE.PENDING,
    COLOR: "#fff787",
    DESCRIPTION: "Comanda realizada",
  },
  {
    STATE: TABLE_STATE.PAYING,
    COLOR: "#ff8787",
    DESCRIPTION: "Pago parcial",
  },
  {
    STATE: TABLE_STATE.PAYED,
    COLOR: "#99cc99",
    DESCRIPTION: "Pago completo",
  },
];

/**
 * Colores de jerarquía de una comanda
 */
export const CommandColors = [
  {
    HIERARCHY_CODE: 0,
    COLOR: "#008004",
    DESCRIPTION: "#0",
  },
  {
    HIERARCHY_CODE: 1,
    COLOR: "#EDA600",
    DESCRIPTION: "#1",
  },
  {
    HIERARCHY_CODE: 2,
    COLOR: "#C44900",
    DESCRIPTION: "#2",
  },
  {
    HIERARCHY_CODE: 3,
    COLOR: "#00E6BB",
    DESCRIPTION: "#3",
  },
  {
    HIERARCHY_CODE: 4,
    COLOR: "#014F86",
    DESCRIPTION: "#4",
  },
  { HIERARCHY_CODE: 5, COLOR: "#7F00A6", DESCRIPTION: "#5" },
  { HIERARCHY_CODE: 6, COLOR: "#323232", DESCRIPTION: "#6" },
];

export const DEFAULT_ICON_COLOR = "#434343";
export const DEFAULT_COMMAND_COLOR = "#FFFFFF";
