/**
 * Define el tipo para respuestas de servicios
 */
export type FetchResponseType<T> = {
  result_code: number;
  response: T;
};

/**
 * Define el tipo para carga de imágenes en rsuite Uploader
 */
export type FileListType = {
  name: string;
  fileKey: number;
  url: string;
}[];

export enum SCHEDULE_TYPES {
  BUSINESS = "BUSINESS",
  DELIVERY = "DELIVERY",
  TAKEAWAY = "TAKEAWAY",
}

export type SCHEDULE_JSON_HOUR_RANGE = {
  range: string;
};

export enum ORDER_TYPES {
  readonly = "readonly",
  local = "local",
  delivery = "delivery",
  takeaway = "takeaway",
}

export enum PAYMENT_METHODS {
  CARD = "CARD",
  CASH = "CASH",
  STRIPE = "STRIPE",
}

export enum TRIGGER_TYPES {
  SESSION_TIME = "SESSION_TIME",
  MOMENT = "MOMENT",
  PAYMENT = "PAYMENT",
  ORDER = "ORDER",
  CATEGORY = "CATEGORY",
}

export enum MOMENT_TYPE {
  MORNING = "MORNING",
  NOON = "NOON",
  NIGHT = "NIGHT",
}
