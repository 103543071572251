export const enum METHODS {
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
  POST = "POST",
}

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const imageHeaders = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
};

