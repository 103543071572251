import { ORDER_TYPES } from "../constants";
import {
  AppContextInterface,
  Cart,
  MenuInCart,
  SimpleProductInCart,
} from "./Context";
import { PARAMS, ReadParameter } from "./ParametersController";
import { PriceFormatter } from "./Utils";

export function OrderTypeValidation(
  new_order_type: ORDER_TYPES | null,
  previous_order_type: ORDER_TYPES | null
): boolean {
  if (previous_order_type == null) return true;
  if (new_order_type == previous_order_type) return true;

  return false;
}

export const SaveMenuCart = (c: MenuInCart[]) => {
  sessionStorage.setItem("menu_cart", JSON.stringify(c));
};

export const GetMenuCart = (): MenuInCart[] | null => {
  const c: MenuInCart[] = JSON.parse(
    sessionStorage.getItem("menu_cart") || "null"
  );
  return c;
};

export const EmptyMenuCart = () => {
  sessionStorage.removeItem("menu_cart");
};

export const SaveCart = (c: Cart) => {
  sessionStorage.setItem("cart", JSON.stringify(c));
};

export const GetCart = (): Cart | null => {
  const c: Cart = JSON.parse(sessionStorage.getItem("cart") || "null");
  return c;
};

export const EmptyCart = () => {
  sessionStorage.removeItem("cart");
};

/// CalcTotalPrice
export function CalcTotalCartPrice(
  cart: Cart | null,
  menuCart: MenuInCart[] | null,
  appContext: AppContextInterface | null,
  format = true
) {
  if (appContext == null) return PriceFormatter(0);
  let total = 0;

  const supplement_delivery = ReadParameter(PARAMS.supplement_delivery);

  if (
    supplement_delivery != "" &&
    appContext.orderType == ORDER_TYPES.delivery
  ) {
    total += parseFloat(supplement_delivery);
  }

  cart?.products.forEach((item) => {
    total += item.price * item.amount;

    item.addons?.forEach((addon) => {
      if (addon.add) total += parseFloat(addon.price + "") * item.amount;
    });
  });

  menuCart?.forEach((item) => {
    total += parseFloat(item.menu_price + "");

    item.products?.forEach((cp) => {
      if (cp.supplement > 0) total += cp.supplement * cp.amount;
      if (cp.addons && cp.addons.length > 0)
        cp.addons.forEach((ad) => {
          if (ad.add && ad.pay_in_menu) total += ad.price * cp.amount;
        });
    });
  });

  return format ? PriceFormatter(total) : total;
}

/**
 *
 * @param appContext
 * @param product
 * @param price
 */
export function AddProductToCart(
  appContext: AppContextInterface | null,
  product: SimpleProductInCart,
  price: number
) {
  const productsInCart: SimpleProductInCart[] = GetCart()?.products || [];

  let auxArray: SimpleProductInCart[] = [...productsInCart];
  const productIndex: number = productsInCart?.findIndex(
    (p) => p.id == product.id
  );

  auxArray.push({
    id: product.id,
    amount: product.amount,
    name: product.name || "",
    observation: product.observation,
    price: price,
    addons: product.addons,
    delay: 0,
    can_delay: product.can_delay || false,
  });

  SaveCart({
    products: auxArray,
    type: appContext?.orderType || ORDER_TYPES.local,
  });

  appContext?.setOrderTs(new Date().getTime());
}
