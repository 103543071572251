import { ORDER_TYPES } from "../constants";
import {
  CategoriesResponse,
  LandingResponse,
  ProductsResponse,
} from "../services";

/**
 * Keys of parameters
 */
export enum PARAMS {
  address = "address",
  card = "enable_card",
  cash = "enable_cash",
  cover = "cover",
  background_color = "background_color",
  header_color = "header_color",
  delivery = "enable_delivery",
  delivery_zip_code = "delivery_zip_code",
  font_color = "font_color",
  id = "id",
  live_state = "enable_live_state",
  logo = "logo",
  min_delivery = "min_delivery",
  name = "name",
  order_secret = "order_secret",
  orders = "enable_orders",
  order_type = "order_type",
  permission = "permission",
  phone = "phone",
  primary_color = "primary_color",
  reservation = "enable_reservation",
  secondary_color = "secondary_color",
  slug = "slug",
  stripe = "enable_stripe",
  stripe_acct_id = "stripe_acct_id",
  table = "table",
  takeaway = "enable_takeaway",
  wait_time = "enable_waiting_time",
  zip_code = "zip_code",
  supplement_delivery = "supplement_delivery",
  max_guests = "max_guests",
  max_days_before = "max_days_before",
  previous_message = "previous_message",
  post_message = "post_message",
}

export enum CACHE {
  categories = "categories",
  products = "products",
  data = "data",
}

/**
 * Loads all parameters received from landing page
 * @param _P Response from landing page
 */
export function LandingParameterLoad(response: LandingResponse) {
  const prms = response.parameters;
  const data = response.data;

  // Parameters
  sessionStorage.setItem(PARAMS.delivery, prms.enable_delivery.toString());
  sessionStorage.setItem(PARAMS.takeaway, prms.enable_takeaway.toString());
  sessionStorage.setItem(
    PARAMS.reservation,
    prms.enable_reservation.toString()
  );
  sessionStorage.setItem(PARAMS.orders, prms.enable_orders.toString());
  sessionStorage.setItem(PARAMS.wait_time, prms.enable_waiting_time.toString());
  sessionStorage.setItem(PARAMS.live_state, prms.enable_live_state.toString());
  sessionStorage.setItem(PARAMS.min_delivery, prms.min_delivery.toString());
  sessionStorage.setItem(
    PARAMS.supplement_delivery,
    prms.supplement_delivery.toString()
  );
  sessionStorage.setItem(PARAMS.logo, prms.logo ? prms.logo.toString() : "");
  sessionStorage.setItem(PARAMS.cover, prms.cover ? prms.cover.toString() : "");
  sessionStorage.setItem(
    PARAMS.background_color,
    prms.background_color.toString()
  );
  sessionStorage.setItem(PARAMS.header_color, prms.header_color.toString());
  sessionStorage.setItem(PARAMS.primary_color, prms.primary_color.toString());
  sessionStorage.setItem(
    PARAMS.secondary_color,
    prms.secondary_color.toString()
  );
  sessionStorage.setItem(PARAMS.font_color, prms.font_color.toString());
  sessionStorage.setItem(
    PARAMS.table,
    prms.table_id == "INVALID" ? "" : prms.table_id
  );
  sessionStorage.setItem(PARAMS.stripe, prms.enable_stripe.toString());
  sessionStorage.setItem(PARAMS.cash, prms.enable_cash.toString());
  sessionStorage.setItem(PARAMS.card, prms.enable_card.toString());

  sessionStorage.setItem(
    PARAMS.max_days_before,
    prms.max_days_before.toString()
  );
  sessionStorage.setItem(PARAMS.max_guests, prms.max_guests.toString());
  sessionStorage.setItem(PARAMS.post_message, prms.post_message.toString());
  sessionStorage.setItem(
    PARAMS.previous_message,
    prms.previous_message.toString()
  );

  // Data
  sessionStorage.setItem(PARAMS.id, data.id.toString());
  sessionStorage.setItem(PARAMS.name, data.name.toString());
  sessionStorage.setItem(PARAMS.zip_code, data.zip_code.toString());
  sessionStorage.setItem(PARAMS.address, data.address.toString());
  sessionStorage.setItem(PARAMS.slug, data.slug.toString());
  localStorage.setItem(PARAMS.slug, data.slug.toString());
  sessionStorage.setItem(PARAMS.phone, data.phone.toString());
  sessionStorage.setItem(PARAMS.stripe_acct_id, data.stripe_id);

  sessionStorage.setItem(PARAMS.permission, response.permission.toString());
}

/**
 * Removes all stored parameters
 */
export function UnloadParameters() {
  for (let key in PARAMS) sessionStorage.removeItem(key);
}

/**
 * Returns stored value of a parameter
 * @param p Key of parameter
 * @returns Value of parameter
 */
export function ReadParameter(p: PARAMS | string) {
  let stored = sessionStorage.getItem(p);
  if (p === PARAMS.slug) stored = stored ? stored : localStorage.getItem(p);

  return stored == null ? "" : stored;
}

/**
 * Stores value of a parameter
 * @param p Key of parameter
 * @param v Value of parameter
 */
export function WriteParameter(p: PARAMS | string, v: string) {
  sessionStorage.setItem(p, v);

  return p;
}

/**
 * Compares parameter value against some other value
 * @param parameter
 * @param expectedValue
 * @returns
 */
export function CheckParameter(parameter: PARAMS, expectedValue: any) {
  return ReadParameter(parameter) == expectedValue;
}

/**
 *
 * @param t
 * @returns
 */
export function SetOrderType(t: string | ORDER_TYPES) {
  return sessionStorage.setItem(PARAMS.order_type, t);
}

/**
 *
 * @returns
 */
export function GetOrderType(): ORDER_TYPES | null {
  const stored = sessionStorage.getItem(PARAMS.order_type) || "";

  return stored === "" ? null : ORDER_TYPES[stored as keyof typeof ORDER_TYPES];
}

/**
 *
 * @returns
 */
export function GetTableId(): string {
  const stored = sessionStorage.getItem(PARAMS.table) || "";
  return stored;
}

/**
 *
 * @param products
 * @returns
 */
export function SaveProductsInCache(products: ProductsResponse) {
  return sessionStorage.setItem(CACHE.products, JSON.stringify(products));
}

/**
 *
 * @returns
 */
export function ReadProductsInCache(): ProductsResponse | null {
  const v: ProductsResponse = JSON.parse(
    sessionStorage.getItem(CACHE.products) + ""
  );
  return JSON.stringify(v) === "null" ? null : v;
}

/**
 *
 * @param categories
 * @returns
 */
export function SaveCategoriesInCache(categories: CategoriesResponse) {
  return sessionStorage.setItem(CACHE.categories, JSON.stringify(categories));
}

/**
 *
 * @returns
 */
export function ReadCategoriesInCache(): CategoriesResponse | null {
  const v: CategoriesResponse = JSON.parse(
    sessionStorage.getItem(CACHE.categories) + ""
  );
  return JSON.stringify(v) === "null" ? null : v;
}

/**
 *
 * @param data
 * @returns
 */
export function SaveDataInCache(data: LandingResponse) {
  return sessionStorage.setItem(CACHE.data, JSON.stringify(data));
}

/**
 *
 * @returns
 */
export function ReadDataInCache(): LandingResponse | null {
  const v: LandingResponse = JSON.parse(
    sessionStorage.getItem(CACHE.data) + ""
  );
  return JSON.stringify(v) === "null" ? null : v;
}

/**
 *
 * @returns
 */
export function GetColorsInCache(): {
  primary: string;
  secondary: string;
  background: string;
  header: string;
} {
  let obj: {
    primary: string;
    secondary: string;
    background: string;
    header: string;
  } = { primary: "", secondary: "", background: "", header: "" };

  obj.primary = sessionStorage.getItem(PARAMS.primary_color) || "#085f69";
  obj.secondary = sessionStorage.getItem(PARAMS.secondary_color) || "#e6af17";
  obj.background = sessionStorage.getItem(PARAMS.background_color) || "#fafafa";
  obj.header = sessionStorage.getItem(PARAMS.header_color) || "#1f2433";

  return obj;
}
